import $ from 'jquery'

export function invModal() {
    $('[inv-modal]').on('click', function (ev) {
        ev.preventDefault()
        let alvo = $(this).attr('inv-modal')
        $(alvo).toggleClass('aberto')
        $(alvo).attr('aria-hidden') === 'true' ? $(alvo).attr('aria-hidden', 'false') : $(alvo).attr('aria-hidden', 'true')
    })
    $('.fecha-modal').on('click', function () {
        $('.cada-menu').removeClass('aberto')
        $('.cada-menu').attr('aria-hidden', 'true')

    })

    $('.cada-menu h2').on('click', function () {
        $(this).toggleClass('aberto')
    })
}