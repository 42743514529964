import $ from 'jquery'


export function acessibilidadeLibras() {

  function gerarIframe(id) {
    // Gera o iframe do youtube.
    // Os argumentos são passaos dentro do atributo src, nos elementos do GET ex.: '?autoplay=1'
    const iframe = `<iframe src="https://www.youtube.com/embed/${id}?autoplay=1&mute=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
    return iframe
  }


  const botaoLibras = $('#botao-flutuante-libras')
  const playerLibras = $('#player-libras')
  const botaoFechar = $('#fechar-player-libras')
  const containerVideo = $('#container-video')
  const containerSobre = $('#container-sobre')
  const containerInstrucoes = $('#container-instrucoes')
  const elementoAcessivel = $('.alvo-acessibilidade')


  botaoLibras.on('click', function () {
    playerLibras.addClass('aberto')
    elementoAcessivel.addClass('tem-libras')
  })

  botaoFechar.on('click', function () {

    playerLibras.removeClass('aberto')
    elementoAcessivel.removeClass('tem-libras')
    containerInstrucoes.removeClass('d-none')

    containerVideo.addClass('d-none')
    containerSobre.addClass('d-none')

    containerVideo.html('')

  })

  $('#troca-posicao').on('click', function () {
    playerLibras.toggleClass('baixo cima')
  })
  $('#sobre-a-ferramenta').on('click', function () {

    if (containerSobre.hasClass('aberto')) {

      containerSobre.addClass('d-none').removeClass('aberto')
      containerInstrucoes.removeClass('d-none')

    } else {

      containerVideo.addClass('d-none')
      containerInstrucoes.addClass('d-none')

      containerSobre.removeClass('d-none').addClass('aberto')
    }
  })

  elementoAcessivel.on('click mouseenter', function (event) {
    let ytID = $(event.target)[0].classList
    ytID = [...ytID].filter(i => i.match(/id-/))
    ytID = ytID[0].replace('id-', '')
    containerVideo.removeClass('d-none')
    containerSobre.addClass('d-none')
    containerInstrucoes.addClass('d-none')


    if (containerVideo.find('iframe').length == 0 || containerVideo.find('iframe').attr('src').search(ytID) == -1) {
      // Roda a função somente se não há vídeo OU se o vídeo a ser tocado é diferente do atual
      containerVideo.html(gerarIframe(ytID))
    }


  })





}