import $ from 'jquery'
import { slick } from 'slick-carousel'

export default function sliders() {


  $('.slider-classicos-cmi').slick({
    slidesToShow: 3,
    infinite: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]

  })
  $('.galeria-colorir > ul').slick({
    slidesToShow: 3,
    infinite: false,

  })
}