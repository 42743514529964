import '../scss/all.scss'
import $ from 'jquery';
import 'slick-carousel/slick/slick'
import 'bootstrap';
import { invModal } from './modulos/invModal';
import sliders from './modulos/sliders';
import gambi1 from './modulos/gambi';
import { modalProgramacao } from './modulos/modalProgramacao';
import { acessibilidadeLibras } from './modulos/acessibilidadeLibras';
import lightbox from './modulos/lightbox';
$(
  invModal(),
  sliders(),
  gambi1(),
  modalProgramacao(),
  acessibilidadeLibras(),
  lightbox()
)