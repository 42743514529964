import $ from 'jquery'

export function modalProgramacao() {

  const url = `https://${window.location.hostname}/cmi/wp-json/wp/v2/programacao/`
  $('[prog-id]').on('click', function () {
    const postId = $(this).attr('prog-id')
    $(`[spinner-id="${postId}"]`).addClass('aberto')
    const response = $.get(url + postId)
      .done(function () {

        function formatarData(data) {
          let formatada = `${data.getDate()}
          /
          ${data.getMonth()}
          /
          ${data.getFullYear().toString().substring(2)}
           às 
           ${data.getHours()}h${(data.getMinutes() < 10) ? '0' + data.getMinutes() : data.getMinutes()}`
          return formatada
        }
        $(`[spinner-id="${postId}"]`).removeClass('aberto')
        $('.modal-programacao').addClass('aberto')
        console.log(response)
        let data1 = new Date(response.responseJSON.acf.data_inicio)
        let data2 = new Date(response.responseJSON.acf.data_fim)
        data1 = formatarData(data1)
        data2 = formatarData(data2)

        $('.modal-programacao').find('.titulo-programa').text(response.responseJSON.title.rendered)
        $('.modal-programacao').find('.data-inicio-programa').text(data1)
        $('.modal-programacao').find('.data-fim-programa').text(data2)
        $('.modal-programacao').find('.conteudo-programa').html(response.responseJSON.content.rendered)
        $('#share-face').attr('href', `http://www.facebook.com/sharer.php?u=${response.responseJSON.link}`)
        $('#share-twitter').attr('href', `http://twitter.com/home?status=${response.responseJSON.link}`)
        $('#share-link').attr('href', `${response.responseJSON.link}`)
      })
  })

}